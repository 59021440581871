$(document).ready(function(){

    // Donation Amounts Toggle
    if ($('.donation_amounts').length > 0){

        $('.donation_amount').click(function(){
            var donation_amount = $(this);
            var input = donation_amount.find('input');
            var amount = input.val();
            $('.donation_select').removeClass('donation_select-show');
            $('.donation_select[data-amount="' + amount + '"]').addClass('donation_select-show');
            $('#id_amount').val(amount);
            $('#id_custom_amount').val('');
        });
    }

    $(document).on('keyup', '#id_custom_amount', function(){
        var value = $(this).val();
        if(value){
            $('.donation_select').removeClass('donation_select-show');
            $('.donation_amount input').prop('checked',false);
            $('#id_amount').val(value);
        } else {
            $('.donation_select:first').addClass('donation_select-show');
            var input =  $('.donation_amount:first').find('input');
            input.attr('checked', 'checked');
            var amount = input.val();
            $('#id_amount').val(amount);
        }
    });

});